import React from 'react'
import './style.css'
import { FaLongArrowAltRight } from 'react-icons/fa'


export default function Card({ title, description, stack, img, link }) {
  console.log(link.length);
  return (
    <li className='cardProject'>
      <img src={img} alt="" />
      <h3>{title}</h3>
      <p className='description'>{description}</p>
      <p className='stack'>{stack}</p>
      {link.length > 0 ? <a className='linkCard' href={link} target="_blank" rel="noreferrer">Acessar <FaLongArrowAltRight /></a> : <p className='linkCard'>aguarde...</p>}
    </li>
  )
}

//