import './styles.css';

export default function Header() {
    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <header>
            <nav className='nav'>
                <p onClick={scrollTop}>
                    Início
                </p>
                <a href='#about-me'>
                    Sobre mim
                </a>
                <a href='#portfolio'>
                    Portfólio
                </a>
                <a href='#contacts'>
                    Contatos
                </a>
            </nav>
        </header>
    );
}
