import Header from './components/screen/Header/Header';
import Hero from './components/screen/Hero/Hero';
import AboutMe from './components/screen/AboutMe/AboutMe';
import MyProjects from './components/screen/MyProjects/MyProjects';
import Contacts from './components/screen/Contacts/Contacts';
import Footer from './components/screen/Footer/Footer';

import dados from './infos.json'

import { useEffect, useState } from 'react';
import './styles/App.css';

export default function App() {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('Carlos Silva');
  const [text, setText] = useState('');

  useEffect(() => {
    const nameChars = name.split('');

    const intervalId = setInterval(() => {
      setText(text => {
        if (nameChars.length === 0) {
          clearInterval(intervalId);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          return text;
        }
        const [nextChar, ...remainingChars] = nameChars;
        setName(remainingChars.join(''));
        return text + nextChar;
      });
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, [name]);

  return (
    <div className="App">
      {loading && <div className='Loading'> {text} </div>}
      {!loading && (
        <>
          <Header />
          <div className='content-conteiner'>
            <Hero data={dados} />
            <AboutMe data={dados} />
            <MyProjects data={dados} />
            <Contacts />
          </div>
          <Footer />
        </>
      )}
    </div>
  )
}
