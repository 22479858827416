import { FaGithub, FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa'
import './style.css'

export default function Links() {
  return (
    <div className='containerLinks'>
      <h3 className='titleContacts'>Fale comigo</h3>
      <ul className='contactIcons'>

        <li className='cardLink'>
          <FaInstagram className='IconLink' />
          <p className='titleApp'>Instagram</p>
          <a href="https://www.instagram.com/carloosf.__/" target="_blank" rel="noreferrer" >Acessar</a>
        </li>

        <li className='cardLink'>
          <FaLinkedin className='IconLink' />
          <p className='titleApp'>Linkedin</p>
          <a href="https://www.linkedin.com/in/carloosf/"  target="_blank" rel="noreferrer" >Acessar</a>
        </li>

        <li className='cardLink'>
          <FaGithub className='IconLink' />
          <p className='titleApp'>Github</p>
          <a href="https://github.com/carloosf" target="_blank" rel="noreferrer" >Acessar</a>

        </li>
        <li className='cardLink'>
          <FaWhatsapp className='IconLink' />
          <p className='titleApp'>Whatsapp</p>
          <a href="https://api.whatsapp.com/send?phone=+5581971066216&text=Olá%2C+carlos%21" target="_blank" rel="noreferrer" >Acessar</a>

        </li>
      </ul>
    </div>
  )
}
