import './styles.css'
import Links from './Links/Links'
import Form from './Form/Form'

export default function Contacts() {
    return (
        <section id='contacts' className='containerContacts'>
            <h1 className='title'>Contato</h1>
            <div className="contacts">
                <Links />
                <Form />
            </div>
        </section >
    )
}
