import React, { useState, useEffect } from 'react';
import './styles.css';
import Card from './Card/Card';

export default function MyProjects({ data }) {
    const [limitCard, setLimitCard] = useState(4);
    var viewportWidth = window.innerWidth;

    useEffect(() => {
        if (viewportWidth < 800) {
            setLimitCard(4);
        } else {
            setLimitCard(data.projects.length);
        }
    }, [viewportWidth, data.projects.length]);

    function handleLoadMore() {
        setLimitCard(data.projects.length);
    }

    return (
        <section id='portfolio' className='myProjects'>
            <h1 className='title'>Portfólio</h1>
            <div className='MyProjectsGrid'>
                <ul className='cardConteiner'>
                    {data.projects.slice(0, limitCard).map((item, index) => (
                        <Card
                            key={index}
                            title={item.title}
                            description={item.description}
                            stack={item.stack}
                            img={item.img}
                            link={item.link}
                        />
                    ))}
                </ul>
            </div>
            {limitCard === 4 && (
                <button className='loadMoreButton' onClick={handleLoadMore}>Carregar mais</button>
            )}
        </section>
    );
}
