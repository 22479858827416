import './styles.css';
import { Tooltip } from 'react-tippy'
import { DiReact } from 'react-icons/di';
import { SiFastify, SiTypescript } from 'react-icons/si'
import { GrMysql } from 'react-icons/gr'
import { FaJava, FaAws } from 'react-icons/fa'

export default function Hero({ data }) {
    return (
        <section id='hero' className='heroContainer'>
            <div  className='Hero'>
                <div className='InfosHero'>
                    <h1>{data.nome}</h1>
                    <p>{data.cargo}</p>
                </div>
                <ul className='tecnologias'>
                    <Tooltip title='ReactJS' animation='fade' position='bottom' trigger='mouseenter' theme='transparent'>
                        <DiReact />
                    </Tooltip>
                    <Tooltip title='ExpressJS' animation='fade' position='bottom' trigger='mouseenter' theme='transparent'>
                        <SiFastify />
                    </Tooltip>
                    <Tooltip title='Typescript' animation='fade' position='bottom' trigger='mouseenter' theme='transparent'>
                        <SiTypescript />
                    </Tooltip>
                    <Tooltip title='MySql' animation='fade' position='bottom' trigger='mouseenter' theme='transparent'>
                        <GrMysql />
                    </Tooltip>
                    <Tooltip title='Java' animation='fade' position='bottom' trigger='mouseenter' theme='transparent'>
                        <FaJava />
                    </Tooltip>
                    <Tooltip title='AWS' animation='fade' position='bottom' trigger='mouseenter' theme='transparent'>
                        <FaAws />
                    </Tooltip>
                </ul>
            </div>
        </section>
    );
}
