import './styles.css';

export default function AboutMe({ data }) {

    const quotes = [
        { "quote": "Tente mover o mundo – o primeiro passo será mover a si mesmo.", "author": "Platão" },
        { "quote": "A paz do coração é o paraíso dos homens.", "author": "Platão" },
        { "quote": "Onde quer que esteja, esteja por inteiro." , "author": "Eckhart Tolle" },
        { "quote": "Conhece-te a ti mesmo" , "author": "Sócrates" },
        { "quote": "Onde quer que esteja, esteja por inteiro." , "author": "Eckhart Tolle" },
        { "quote": "A simplicidade é o último grau de sofisticação." , "author": "Leonardo da Vinci" },

    ];
    const numRandom = Math.floor(Math.random() * quotes.length);
    const randomQuote = quotes[numRandom];

    return (
        <section id='about-me' className='AboutMe'>
            <h1 className='title'>Sobre mim</h1>
            <div className='contentAboutMe'>
                <div className='apresentationAboutMe'>
                    <p className='textApresentation'>
                        {data.apresentação}
                    </p>
                    <ul className='infosAboutMe'>
                        <li>
                            <h2 > Faculdade:</h2> <p className='faculdade'></p>
                        </li>
                        <li>
                            <h2> Curso: </h2> <p>{data.curso}</p>
                        </li>
                        <li>
                            <h2> Periodo: </h2> <p>{data.periodo}°</p>
                        </li>
                    </ul>
                </div>
                <div className='quote-container'>
                    <p className='quote'>{randomQuote.quote}</p>
                    <p className='quote-author'>{randomQuote.author}</p>
                </div>
            </div>
        </section>
    );
}
