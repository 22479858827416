import React from 'react'
import './style.css'

export default function Footer() {
  return (
    <footer className='footer'>
      <p>Criado por Carlos silva &#128147;</p>
    </footer>
  )
}
