import React, { useState } from 'react'
import { IoIosSend } from 'react-icons/io'
import emailjs from '@emailjs/browser';
import './style.css'

export default function Form() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')


  function handlerEmail(e) {
    e.preventDefault()

    const templateParams = {
      name: name,
      email: email,
      message: message,
    }

    emailjs.send("service_jbdctg5", "template_awlx6yx", templateParams, "vxK7DMA9DyMXSxRyB")
      .then((result) => {
        console.log(result.text);
        setEmail('')
        setName('')
        setMessage('')
      }, (error) => {
        console.error(error);
      })
  }

  return (
    <div className='containerForm'>
      <h3 className='titleContacts'>Me escreva sua mensagem</h3>
      <form className='formContact' onSubmit={handlerEmail}>
        <input type='text'
          placeholder='Nome'
          onChange={(e) => setName(e.target.value)}
          value={name}
          required />

        <input type="email" name="email"
          placeholder='Email'
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required />

        <textarea name="message" cols="30" rows="4"
          placeholder='Mensagem...'
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          require=""
        />
        <button type='submit'> Enviar <IoIosSend className='iconSendEmail' /> </button>
      </form>
    </div>
  )
}
